/* You can add global styles to this file, and also import other style files */
.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}
ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

.p-tooltip .p-tooltip-text {
    max-width: 400px;
    padding: 0.75rem 1rem !important;
    color: #3F4254 !important;
    text-align: center;
    font-family: "Exo 2" !important;
    font-size: 12px;
    line-height: 18px;
    
    background-color: #ffffff !important;
    border-radius: 0.475rem !important;
}

.form-group {
    margin-bottom: 1rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-5 {
    margin-right: 3rem;
}

input.ng-invalid, textarea.ng-invalid, select.ng-invalid, p-dropdown.ng-invalid>div.p-dropdown, p-dropdown.is-invalid>div.p-dropdown {
  border-color: var(--bs-danger) !important;
}

.ng-invalid-parent > label {
    color: red !important;
}

.p-dropdown {
    border: 1px solid #E4E6EF !important;
}

.p-dropdown, .p-inputtext {
    font-size: 1.1rem !important;
    color: #181C32 !important;
    font-weight: 500 !important;
}

.btn.btn-warning {
    background-color: #fdc93d !important;
}

:root {
    --font-family: "Exo 2", Helvetica, "sans-serif";
}

.p-component, .p-link, .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input,
.p-chips .p-chips-multiple-container .p-chips-input-token input, .p-inputtext, .p-terminal .p-terminal-input {
    font-family: "Exo 2", Helvetica, "sans-serif" !important;
}

h1,h2,h3,h4,h5,h6 {
    font-family: "Exo 2", Helvetica, "sans-serif" !important;
}

#kt_wrapper {
    background-color: #fff;
}

#kt_aside {
    background-color: #fff;
    box-shadow: none;
}

#kt_aside_logo {
    background-color: #f2f5f7;
}

#kt_aside_menu {
    font-family: "Exo 2";
}


#kt_aside_menu .menu-title {
    font-weight: bold;
}

#kt_aside_menu .menu-subnav .menu-title {
    font-weight: 300;
}

#kt_header, #kt_footer {
    background-color: #f2f5f7;
}

.subheader {
    background-color: #fff;
}

.subheader.subheader-solid.toolbar {
    box-shadow: none;
    border-top: 0;
}

.card {
    box-shadow: none;
}

#kt_routeroutlet {
    flex: 1;
    border-radius: 24px 0 0 0;
    background-color: #f2f5f7;
}

.btn.icononly i {
    padding-right: 0;
}

.showEllipsisOnOverflowingText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.badge.badge-warning {
    color: var(--bs-body-color);
}

.pi-sort-alt:before {
    font-family: "Font Awesome 6 Pro";
    content: '\f0dc';
}

.pi-sort-amount-up-alt:before {
    font-family: "Font Awesome 6 Pro";
    content: '\f0de';
}

.pi-sort-amount-down:before {
    font-family: "Font Awesome 6 Pro";
    content: '\f0dd';
}

.btn-sm {
    box-shadow: none !important;
    border: 0 !important;
}

.btn-sm.btn-primary, .btn-sm.btn-primary i {
    color: white !important;
}

.btn-sm i {
    display: inline-block;
    margin-right: 3px;
}

.tobepushed {
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.pushtoleft {
    width: calc(100% - 360px);
}

.stdTableCheckboxColumn {
    max-width: 50px;
}

.stdTableActionsColumn {
    max-width: 130px;
}

.stdTableNumberColumn {
    max-width: 120px;
}

.stdTableImgColumn {
    max-width: 120px;
}

.stdTableTextColumn {
    min-width: 150px;
}

.stdTableBadgeColumn {
    max-width: 100px;
}

.stdTableButtonColumn-2 {
    max-width: 60px;
}

.stdTableButtonDiv-2 {
    width: 60px;
}

.stdTableButtonColumn-3 {
    max-width: 90px;
}

.stdTableButtonDiv-3 {
    width: 90px;
}

.stdTableButtonColumn-4 {
    max-width: 120px;
}

.stdTableButtonDiv-4 {
    width: 120px;
}

.gapactions {
    gap: 5px;
}

.btn.justicon i {
    font-size: 1.3rem;
    padding-right: 0;
}