/* asap-regular - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  src: url('./Asap/asap-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Asap/asap-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Asap/asap-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Asap/asap-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./Asap/asap-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Asap/asap-v22-latin-regular.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-500 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  src: url('./Asap/asap-v22-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Asap/asap-v22-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Asap/asap-v22-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Asap/asap-v22-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./Asap/asap-v22-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Asap/asap-v22-latin-500.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-600 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  src: url('./Asap/asap-v22-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Asap/asap-v22-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Asap/asap-v22-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Asap/asap-v22-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./Asap/asap-v22-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Asap/asap-v22-latin-600.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-700 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  src: url('./Asap/asap-v22-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Asap/asap-v22-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Asap/asap-v22-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Asap/asap-v22-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./Asap/asap-v22-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Asap/asap-v22-latin-700.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 400;
  src: url('./Asap/asap-v22-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Asap/asap-v22-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Asap/asap-v22-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Asap/asap-v22-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('./Asap/asap-v22-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Asap/asap-v22-latin-italic.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-500italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 500;
  src: url('./Asap/asap-v22-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Asap/asap-v22-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Asap/asap-v22-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Asap/asap-v22-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('./Asap/asap-v22-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Asap/asap-v22-latin-500italic.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-700italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 700;
  src: url('./Asap/asap-v22-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Asap/asap-v22-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Asap/asap-v22-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Asap/asap-v22-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('./Asap/asap-v22-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Asap/asap-v22-latin-700italic.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-600italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 600;
  src: url('./Asap/asap-v22-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Asap/asap-v22-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Asap/asap-v22-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Asap/asap-v22-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('./Asap/asap-v22-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Asap/asap-v22-latin-600italic.svg#Asap') format('svg'); /* Legacy iOS */
}